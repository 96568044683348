import React from "react";

// const Client = [
//   {
//     img: "c1",

//   },
//   {
//     img: "c2",

//   },
//   {
//     img: "c3",
//   },
//   {
//     img: "c4",

//   },
//   {
//     img: "c5",

//   },
//   {
//     img: "c6",
//   },
//   {
//     img: "c8",

//   },
//   {
//     img: "c9",
//   },
// ];

const Awards = () => {
  return (
    <>
      <div className="row">
        {/* {Client.map((val, i) => (
          <div className="col-lg-3 m-15px-tb" key={i}> */}
            {/* <div className="feature-box-02 d-flex align-items-center">
              <div className="icon">
                <img src={`img/award/${val.img}.png`} alt="award image" />
              </div>
              <div className="media-body">
                <h6>{val.awardName}</h6>
                <p>{val.awardFor}</p>
              </div>
            </div> */}
{/* 
            <div>
            <img height="120"src={`img/client/${val.img}.png`} alt="Client Image" />
            </div> */}


            



          {/* </div> */}
          
          {/* // End .col */}
        {/* // ))} */}

        <div className="text-center">
        <img className="client-display" height="700"src={`img/client/client-desktop.jpg`}/>
        <img className="client-display-1" width="600"src={`img/client/client-mobile.jpg`}/>
        </div>
      </div>
      {/* End .row */}
    </>
  );
};

export default Awards;
