import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Fade from "react-reveal/Fade";

export default function SimpleSlider() {
  const settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: false,
    margin: 30,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const TestimonilContent = [
    {
      imageName: "team",
      desc: `  Done in a professional way on time      `,
      // reviewerName: "Nancy Byers",
      // designation: "CEO at ib-themes",
    },
    {
      imageName: "team",
      desc: ` Well structured and punctual with quality work     `,
      // reviewerName: "Jara Afsari",
      // designation: "CEO at ib-themes",
    },
    {
      imageName: "team",
      desc: ` Nice and professional work      `,
      // reviewerName: "Janiaya kiaram",
      // designation: "Visual Designer",
    },
    {
      imageName: "team",
      desc: ` High Quality Job and punctual      `,
      // reviewerName: "Janiaya kiaram",
      // designation: "Visual Designer",
    },
  ];

  return (
    <div className="testimonial-wrapper">
      <Slider {...settings}>
        {TestimonilContent.map((val, i) => (
          <div key={i}>
            <div className="testimonial-01 media">
              <Fade bottom>
                <div className="avatar">
                  <img
                    src={`img/testimonial/${val.imageName}.jpg`}
                    alt="review comments"
                  ></img>
                </div>
                <div className="media-body">
                  <p>{val.desc}</p>
                  <h6>{val.reviewerName}</h6>
                  <span>{val.designation}</span>
                </div>
              </Fade>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
