import React from "react";
import Fade from "react-reveal/Fade";

const ServiceContent = [
  {
    img: "sm",
    icon: "icon-mobile",
    title: "Social Media Marketing:",
    descriptions: `We use social media as an idea generator, trend mapper, and strategic compass for all of our online business ventures.
    Facebook Marketing, Instagram Marketing, LinkedIn Marketing, Twitter Marketing, Ad Campaign Marketing, Google Pay Per Click (PPC).`,
  },
  {
    img: "creative",
    icon: "icon-desktop",
    title: "Creative Designing:",
    descriptions: `A graphic designing agency with your mind.
    Branding, Social Media Designing, Logo Designing and etc.`,
  },
  {
    img: "graphic",
    icon: "icon-target",
    title: "UI/UX Designing:",
    descriptions: `Simplicity is everything when it comes to great UX. As Albert Einstein famously said, “Any darn fool can make something complex; it takes a genius to make something simple.”`,
  },
  {
    img: "web",
    icon: "icon-target",
    title: "Website Development: ",
    descriptions: `“Responsive Web Design always plays important role whenever going to promote your website.”E-commerce Websites, Business Websites, Portfolio Websites and Custom Development.`,
  },
  {
    img: "NFT",
    icon: "icon-target",
    title: "NFT",
    descriptions: `We design unique elements, Pixel Art, 2D/3D Animated GIF also.`,
  },
  {
    img: "app",
    icon: "icon-target",
    title: "App Development",
    descriptions: `“An app is not all about technology
    It is a dream towards the future.”
    Swift ios app, Android Application, React Native (ios, Android Application).`,
  },
];
export default function Service() {
  return (
    <>
      <div className="row ">
        {ServiceContent.map((val, i) => (
          <div className="col-md-6 col-lg-4 mt-2" key={i}>
            <div className="feature-box-01">
              <Fade bottom>
                <div className="icon">
                    <img height="50" src={`img/award/${val.img}.png`} alt="Service-icon" />
                </div>
                <div className="feature-content">
                  <h5>{val.title}</h5>
                  <p>{val.descriptions}</p>
                </div>
              </Fade>
            </div>
            {/* End .feature-box-01 */}
          </div>
        ))}
      </div>
    </>
  );
}
